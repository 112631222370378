
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model;
    const documentStatus = ref();
    const sdate = ref();
    const edate = ref();
    const enums = model?.enums as any;

    const grid = ref<any>({});
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '研究任務追踨統計管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      canDelete: false,
      canUpdate: false,
      canCreate: false,
      canRead: false,
      printConfig: { sheetName: '研究任務追踨統計管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '研究任務追踨統計管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Number', title: '文件編號', showHeaderOverflow: true, showOverflow: true },
        { field: 'Title', title: '標題', showHeaderOverflow: true, showOverflow: true },
        { field: 'TopicType', title: '主題類型', showHeaderOverflow: true, showOverflow: true },
        { field: 'Program', title: '產業類別', showHeaderOverflow: true, showOverflow: true },
        { field: 'ContentType', title: '內容類型', showHeaderOverflow: true, showOverflow: true },
        { field: 'SourceType', title: '來源類型', showHeaderOverflow: true, showOverflow: true },
        { field: 'ReportUser', title: '負責人', showHeaderOverflow: true, showOverflow: true },
        { field: 'Coauthor', title: '共同作者', showHeaderOverflow: true, showOverflow: true },
        { field: 'PublishedDate', title: '出版日期', showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'Status', title: '文件狀態', showHeaderOverflow: true, showOverflow: true },
        { field: 'RecordTime', title: '簽核時間', showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'RecordStatus', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true },
        { field: 'RecordUser', title: '簽核人員', showHeaderOverflow: true, showOverflow: true },
        { field: 'Comment', title: '簽核意見', showHeaderOverflow: true, showOverflow: true },
      ],
      promises: {
        query: model ? (params: any) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(sdate.value) {
            params.sdate = formatDate(sdate.value, 'yyyy-MM-dd')
          } else {
            params.sdate = null;
          }
          if(edate.value) {
            params.edate = formatDate(edate.value, 'yyyy-MM-dd')
          } else {
            params.edate = null;
          }
          if(documentStatus.value) {
            params.keyword = documentStatus.value
          }
          console.log(params.date, params.keyword)
          return model.dispatch('report/queryDocumentStatistics', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('report/queryDocumentStatistics') : undefined,
        save: model ? (params) => model.dispatch('report/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '文件編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TopicType',
          title: '主題類型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContentType',
          title: '內容類型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Program',
          title: '產業類別',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SourceType',
          title: '來源類型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ReportUser',
          title: '負責人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Coauthor',
          title: '共同作者',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: '文件狀態',
          title: 'Status',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'RecordTime',
          title: '簽核時間',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'RecordStatus',
          title: '簽核狀態',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'RecordUser',
          title: '簽核人員',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        { field: 'Comment', title: '簽核意見', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '', disabled: 'true' } } },
      ],
      rules: {
      }
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async() => {
      const params = {} as any;
      if(sdate.value) {
        params.sdate = formatDate(sdate.value, 'yyyy-MM-dd')
      } else {
        params.sdate = null;
      }
      if(edate.value) {
        params.edate = formatDate(edate.value, 'yyyy-MM-dd')
      } else {
        params.edate = null;
      }
      if(documentStatus.value) {
        params.keyword = documentStatus.value
      }
      try {
        var data = await model!.dispatch('report/queryDocumentStatistics', { keyword: params.keyword, sdate: params.sdate, edate: params.edate, export: true });
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // console.log(file)
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "研究任務追踨統計.xlsx";
        link.click();
      } catch (error: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error,
        });
      }      
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      documentStatus,
      sdate,
      edate,
      exportExcel,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
